'use client';

import menu_data from "@/data/menu-data";
import useGlobalContext from "@/hooks/use-context";
import Link from "next/link";
import React, { useState } from "react";

const MobileMenu = () => {
  const { toggleSideMenu } = useGlobalContext();
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);
  const [openMegaMenu, setOpenMegaMenu] = useState<number | null>(null);

  const handleActiveSubMenu = (index: number) => {
    setOpenSubMenu((prev) => (prev === index ? null : index));
    setOpenMegaMenu(null);
  };

  const handleActiveMegaMenu = (index: number) => {
    setOpenMegaMenu((prev) => (prev === index ? null : index));
  };

  return (
    <ul>
      {menu_data?.map((item) => (
        <li
          key={item.id}
          className={`${item.className || ""} ${item.hasDropdown ? "menu-item-has-children" : "mean-last"} ${
            openSubMenu === item.id ? "dropdown-opened" : ""
          }`}
        >
          <Link onClick={toggleSideMenu} href={item.link}>
            {item.title}
          </Link>

          {item.hasDropdown && (
            <>
              <button
                onClick={() => handleActiveSubMenu(item.id)}
                className={`mean-expand ${openSubMenu === item.id ? "mean-clicked" : ""}`}
                aria-expanded={openSubMenu === item.id}
                style={{ fontSize: "18px" }}
              >
                <i className="fal fa-plus"></i>
              </button>

              <ul className={`sub-menu ${openSubMenu === item.id ? "visible" : "hidden"}`}>
                {item.submenus?.map((subItem, subIndex) => (
                  <li
                    key={subIndex}
                    className={`sub-menu-item ${item.megaMenu ? "menu-item-has-children" : ""}`}
                  >
                    <Link onClick={toggleSideMenu} href={subItem.link}>
                      {subItem.title}
                    </Link>

                    {item.megaMenu && subItem.megaMenu && (
                      <>
                        <button
                          onClick={() => handleActiveMegaMenu(subIndex)}
                          className={`mean-expand ${openMegaMenu === subIndex ? "mean-clicked" : ""}`}
                          aria-expanded={openMegaMenu === subIndex}
                          style={{ fontSize: "18px" }}
                        >
                          <i className="fal fa-plus"></i>
                        </button>

                        <ul className={`mega-menu ${openMegaMenu === subIndex ? "visible" : "hidden"}`}>
                          {subItem.megaMenu.map((megaItem, megaIndex) => (
                            <li key={megaIndex}>
                              <Link onClick={toggleSideMenu} href={megaItem.link} className="link-menu">
                                {megaItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export default MobileMenu;
